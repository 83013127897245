<template>
  <div class="dota">
    <div class="dota-warp">
      <div class="data-top">
        <span>选择你要取回的饰品</span>
        <!-- <span>背包流水></span> -->
      </div>
      <div class="hint">
        <i class="el-icon-warning"></i>
        <span>取回注意事项：</span><br />
        <span
          >拒绝收货超过3次，账户会被冻结12小时，取回后需要快速发货，联系客服QQ：122226672</span
        ><br />
      </div>
      <div class="dota-sel">
        <div class="sel-left">
          <!-- <span>价格从低到高</span> -->
          <!-- <div class="rule-btn" @click="goRule('getbackRule')">
            回收/取回规则
          </div>-->
        </div>
        <div class="sel-right">
          <div class="right-one">
            <span class="span1" @click="selAll()" v-if="checkedPrice > 0"
              ><span style="color: #e9b10e; padding: 0">
                ({{ checkedPrice }}) </span
              >选择当前页
            </span>
            <span class="span1" @click="selAll()" v-else>选择当前页</span>
            <span class="span1" @click="offAll()">取消选择</span>
            <el-button
              class="span2"
              @click="coniAll()"
              size="small"
              :disabled="loading"
              ><i v-if="loading" class="el-icon-loading"></i>兑换</el-button
            >
            <!--<span class="span3">取回</span> -->
          </div>
        </div>
      </div>

      <div class="dota-list">
        <ul>
          <li
            class="li"
            v-for="(item, index) in list"
            :key="index"
            @click="check(item.id)"
          >
            <div class="list-warp">
              <div class="warp3" v-if="item.state1"><span>取回中</span></div>
              <div class="warp1" v-if="item.state"></div>
              <img
                class="warp2"
                v-if="item.state"
                src="../assets/img/gou.png"
              />
              <span class="ico">{{ item.exteriorName }}</span>
              <span v-if="item.state" class="buy-state">
                <img src="../assets/img/gou.png" />
              </span>
              <div class="list-img">
                <img :src="item.img" />
              </div>
              <div class="bot">
                <div class="list-name" :title="item.name">{{ item.name }}</div>
                <div class="list-pirce">
                  <div class="pirce-left">
                    <img src="../assets/img/masonry.png" />
                    <span>{{ item.price }}</span>
                  </div>
                  <div class="pirce-right">
                    <div class="btn-item" @click="getExchange($event, item.id)">
                      回收
                    </div>
                    <div
                      class="btn-item"
                      @click="getPull($event, item.id, item.itemId)"
                    >
                      取回
                    </div>
                    <!-- <el-button
                      class="spanbtn1"
                      @click="getExchange($event, item.id)"
                      :disabled="item.loading1"
                      ><i v-if="item.loading1" class="el-icon-loading"></i
                      >兑换</el-button
                    >
                    <el-button
                      class="spanbtn2"
                      @click="getPull($event, item.id, item.itemId)"
                      :disabled="item.loading2"
                      ><i v-if="item.loading2" class="el-icon-loading"></i
                      >取回</el-button
                    > -->
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="clear"></div>
        <div class="roll-page">
          <el-pagination
            background
            :pager-count="4"
            layout="prev, pager, next"
            :total="total"
            :page-size="pageSize"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <span :plain="true">{{ exchangeHint }}</span>
    <navheader
      :showNav="showNav"
      :yidongmenu="yidongmenu"
      :openHongbao="openHongbao"
    ></navheader>
  </div>
</template>

<script>
import Utils from "./../assets/js/util.js";
import navheader from "@/components/navheader.vue";
export default {
  components: { navheader },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      loading: false,
      total: 0,
      page: 1,
      pageSize: 24,
      list: [],
      exchangeHint: "",
      checkedPrice: 0,
      random: "",
    };
  },
  methods: {
    goRule(pathName) {
      this.$router.push({
        name: pathName,
      });
    },
    //兑换多个
    coniAll() {
      this.loading = true;
      var arr = [];
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].state1) {
          this.$message({
            message: "正在取回中，稍后重试",
            type: "warning",
          });
          this.loading = false;
          return;
        }
      }
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].state) {
          arr.push(this.list[i].id);
        }
      }
      let param = {
        player_skins_ids: arr,
      };
      this.$axios
        .post("/index/User/exchangeToMoney", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.loading = false;
            this.winState = false;
            this.$store.commit("getMoney", res.data.data.total_amount);
            Utils.$emit("masonry", res.data.data.total_amount);
            this.getList(1);
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
            this.checkedPrice = 0;
          } else {
            this.$message({
              showClose: true,
              message: data.msg,
              type: "error",
            });
            this.loading = false;
          }
        });
    },
    //单个选中
    check(id) {
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].id == id && this.list[i].state1 == false) {
          this.list[i].state = !this.list[i].state;
          if (this.list[i].state == true) {
            this.checkedPrice = (
              parseFloat(this.checkedPrice) + parseFloat(this.list[i].price)
            ).toFixed(2);
          } else {
            this.checkedPrice = (
              parseFloat(this.checkedPrice) - parseFloat(this.list[i].price)
            ).toFixed(2);
          }
        }
      }
      // console.log(this.checkedPrice);
      this.$forceUpdate();
    },
    //选择当前页
    selAll() {
      this.checkedPrice = 0;
      for (let i = 0; i < this.list.length; i++) {
        if (!this.list[i].state1) {
          this.list[i].state = true;
        }
        this.checkedPrice = (
          parseFloat(this.checkedPrice) + parseFloat(this.list[i].price)
        ).toFixed(2);
      }
      this.$forceUpdate();
    },
    //取消当前页
    offAll() {
      this.checkedPrice = 0;
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].state = false;
        this.checkedPrice = (
          parseFloat(this.checkedPrice) - parseFloat(this.list[i].price)
        ).toFixed(2);
      }
      this.$forceUpdate();
    },
    //分页
    currentChange(val) {
      this.getList(val);
      this.checkedPrice = 0;
    },
    //背包数据
    getList(page) {
      this.page = page;
      let param = {
        page: page,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("/index/User/packageListnew", this.$qs.stringify(param))
        .then((res) => {
          console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.total = data.data.total;
            this.list = data.data.skinList;
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].state = false;
              this.list[i].state1 = false;
              this.list[i].loading1 = false;
              this.list[i].loading2 = false;
            }
            if (data.data.skinList.length == 0) {
              let param = {
                page: this.page - 1,
                pageSize: this.pageSize,
              };
              this.$axios
                .post("/index/User/packageList", this.$qs.stringify(param))
                .then((res) => {
                  // console.log(res.data);
                  var data = res.data;
                  if (data.status == "1") {
                    this.total = data.data.total;
                    this.list = data.data.skinList;
                    for (let i = 0; i < this.list.length; i++) {
                      this.list[i].state = false;
                      this.list[i].state1 = false;
                      this.list[i].loading1 = false;
                      this.list[i].loading2 = false;
                    }
                  }
                });
            }
          } else {
            this.total = 0;
            this.list = [];
          }
        });
    },
    //点击兑换
    getExchange(event, id) {
      event.stopPropagation();
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].state1) {
          this.$message({
            message: "正在取回中，稍后重试",
            type: "warning",
          });
          return;
        }
      }
      for (let i = 0; i < this.list.length; i++) {
        if (id == this.list[i].id) {
          this.list[i].loading1 = true;
          this.list[i].loading2 = true;
        }
      }
      this.$forceUpdate();
      let param = {
        player_skins_ids: [id],
      };
      this.$axios
        .post("/index/User/exchangeToMoneynew", this.$qs.stringify(param))
        .then((res) => {
          console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.getList(this.page);
            this.$store.commit("getMoney", res.data.data.total_amount);
            Utils.$emit("masonry", res.data.data.total_amount);
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
              duration: 800,
            });
          } else {
            for (let i = 0; i < this.list.length; i++) {
              if (id == this.list[i].id) {
                this.list[i].loading1 = false;
                this.list[i].loading2 = false;
              }
            }
            this.$forceUpdate();
            this.$message({
              showClose: true,
              message: "兑换失败，此类饰品不可兑换",
              type: "warning",
              duration: 800,
            });
          }
        });
    },
    //点击取回
    getPull(event, id, steamId) {
      event.stopPropagation();
      for (let i = 0; i < this.list.length; i++) {
        if (id == this.list[i].id) {
          this.list[i].loading1 = true;
          this.list[i].loading2 = true;
          this.list[i].state1 = true;
        }
      }
      this.$forceUpdate();
      let param = {
        player_skins_id: id,
        itemId: steamId,
        random: this.random,
      };
      this.$axios
        .post("/index/User/skinToSteam1new", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.getList(this.page);
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
          } else {
            var hint = "";
            if (data.msg.indexOf("余额不足") != -1) {
              hint = "取回错误，请联系客服";
            } else {
              hint = data.msg;
            }
            this.$message({
              showClose: true,
              message: hint,
              type: "warning",
            });
            for (let i = 0; i < this.list.length; i++) {
              if (id == this.list[i].id) {
                this.list[i].loading1 = false;
                this.list[i].loading2 = false;
                this.list[i].state1 = false;
              }
            }
            this.$forceUpdate();
          }
          this.randomString();
        });
    },
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      this.random = n;
    },
  },
  mounted() {
    this.getList(this.page);
    this.randomString();
  },
};
</script>

<style lang="less" scoped>
::v-deep {
  .el-button--small {
    font-weight: bold;
    border-radius: 0 !important;
  }
}
@media screen and(max-width:750px) {
  .dota-list {
    & > ul {
      display: grid !important;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)) !important;
      gap: 0px 10px;
      li {
        width: 100% !important;
      }
    }
  }
}
.dota {
  width: 100;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background-color: #1a1c24;

  .hint {
    margin-top: 10px;
    font-size: 14px;
    color: #c3c3e2;
    i {
      font-size: 20px;
      margin-right: 5px;
    }
  }
  .dota-warp {
    height: 100%;
    padding: 16px;
    .data-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span:first-child {
        color: #848492;
        font-size: 16px;
      }
      span:last-child {
        color: #848492;
        font-size: 14px;
      }
      span:last-child:hover {
        color: #e9b10e;
        cursor: pointer;
      }
    }

    .dota-sel {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .sel-left {
        color: #e9b10e;
        font-size: 12px;
        font-weight: 600;
        display: flex;
        align-items: center;
        margin-left: auto;
        .rule-btn {
          background-color: #e9b10e;
          color: #0f0f0f;
          padding: 5px 5px;
          font-size: 12px;
        }
      }
      .sel-left:hover {
        cursor: pointer;
      }
      .sel-right {
        display: flex;
        align-items: center;
        .right-one {
          span {
            margin-right: 8px;
            padding: 7px 10px;
            font-size: 12px;
            color: #fff;
            font-weight: 600;
          }
          .span1 {
            // background-color: #333452;
            border: 1px solid #e9b10e;
          }
          .span1:hover {
            cursor: pointer;
            background-color: #3a3f50;
          }
          .span2 {
            background-color: #e9b10e;
            color: #1a1c24;
            border-color: #e9b10e;
          }
          .span2:hover {
            cursor: pointer;
            background-color: #f5c432;
          }
          .span3 {
            background-color: #17b4ed;
            color: #1a1c24;
          }
          .span3:hover {
            cursor: pointer;
            background-color: #3eccff;
          }
        }
        /* .right-two /deep/ .el-input__inner{
            background-color: #2b2c3f;
        }*/
      }
    }

    //分页
    .roll-page {
      margin: 10px 0 0px -10px;
      display: flex;
      justify-content: center;
    }
    .roll-page
      /deep/
      .el-pagination.is-background
      .el-pager
      li:not(.disabled).active {
      background-color: #e9b10e;
      color: #1a1c24;
    }
    .roll-page /deep/ .el-pagination.is-background .btn-next,
    /deep/ .el-pagination.is-background .btn-prev,
    /deep/ .el-pagination.is-background .el-pager li {
      background-color: #333542;
      color: #848492;
    }

    .dota-list {
      margin-top: 20px;
      ul {
        display: flex;
        flex-wrap: wrap;
        .li {
          width: 160px;
          margin-bottom: 8px;
          margin-right: 8px;
          .list-warp {
            width: 100%;
            background-color: #2b2c37;
            border-radius: 5px;
            position: relative;
            overflow: hidden;
            background-size: cover;
            background-image: url("../assets/img/box-skins-blue.jpg");

            .warp1 {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(233, 177, 14, 0.1);
              z-index: 33;
            }
            .warp3 {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.3);
              z-index: 66;
              display: flex;
              justify-content: center;
              span {
                margin-top: 40px;
                color: #fff;
                font-size: 20px;
              }
            }
            .warp2 {
              width: 20px;
              height: 20px;
              position: absolute;
              right: 0;
              top: 0;
            }
            .list-bor {
              position: absolute;
              top: 0;
              z-index: 666;
              width: 100%;
              height: 100%;
              border-bottom-left-radius: 6px;
              background-color: rgba(233, 177, 14, 0.1);
            }

            .ico {
              position: absolute;
              top: 0;
              right: 0;
              font-size: 12px;
              border-bottom-left-radius: 6px;
              color: #0d0e0d;
              background-color: rgb(241, 198, 5);
              padding: 2px 4px;
            }
            .buy-state {
              position: absolute;
              top: 0;
              right: 0;
              img {
                width: 15px;
                height: 15px;
              }
            }
            .list-img {
              height: 80px;
              background-size: 100% 100%;
              padding: 0 20px;
              display: grid;
              place-items: center;
              img {
                width: 100%;
                object-fit: contain;
              }
            }
            .bot {
              .list-name {
                padding-left: 5px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 14px;
                padding: 10px 10px 0 10px;
                color: #c3c3e2;
              }
              .list-pirce {
                padding: 10px 5px 10px 5px;
                // display: flex;
                // flex-direction:row-reverse;
                // justify-content: space-between;
                justify-content: flex-end;
                .pirce-left {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 6px;
                  img {
                    width: auto;
                    height: 15px;
                    margin-right: 5px;
                  }
                  span {
                    color: #e9b10e;
                    font-size: 16px;
                    font-weight: bold;
                  }
                }
                .pirce-right {
                  display: flex;
                  justify-content: center;
                  margin-top: 10px;
                  .btn-item {
                    border: 1px solid #e9b10e;
                    padding: 3px 15px;
                    color: #000;
                    border-radius: 4px;
                    background-color: #e9b10e;
                    width: max-content;
                    font-size: 12px;
                    &:hover {
                      color: #000;
                      background-color: #e9b10e;
                      font-weight: bold;
                    }
                    &:last-child {
                      margin-left: 10px;
                    }
                  }
                  span {
                    margin-left: 10px;
                    padding: 4px 15px;
                    border-radius: 5px;
                    font-size: 12px;
                    white-space: nowrap;
                  }
                  span:hover {
                    cursor: pointer;
                  }
                  .spanbtn1 {
                    border: 1px solid #e9b10e;
                    color: #e9b10e;
                    padding: 4px 15px;
                  }
                  .spanbtn2 {
                    padding: 4px 15px;
                    background-color: #e9b10e;
                    border-color: #e9b10e;
                  }
                }
              }
            }
          }
          .list-warp:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
