var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slide"},[_c('vue-seamless-scroll',{staticClass:"warp",attrs:{"data":_vm.listSlide2[0],"class-option":_vm.classOption}},[_c('ul',{staticClass:"ul-item"},_vm._l((_vm.listSlide2[0]),function(item,index2){return _c('li',{key:index2,staticClass:"li-item",style:({
          backgroundImage: 'url(' + item.img + ')',
        }),on:{"click":function($event){return _vm.getBox(item.box_id)}}},[_c('div',{staticClass:"info"},[_c('img',{staticClass:"imgurl",attrs:{"src":item.imageUrl,"art":""}}),_c('div',{staticClass:"box-name__title",style:({
              color: item.color,
            })},[_vm._v(" "+_vm._s(item.skin_name)+" ")])]),_c('div',{staticClass:"info-hover"},[_c('div',{staticClass:"box-name__2"},[_vm._v(_vm._s(item.box_name))]),_c('div',{staticClass:"player-name",style:({
              color: item.color,
            }),attrs:{"title":item.player_name}},[_c('img',{attrs:{"src":item.player_img}}),_vm._v(" "+_vm._s(item.player_name)+" ")])])])}),0)]),_c('div',{staticClass:"clear"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }